import { InboxOutlined } from "@ant-design/icons";
import { Collapse, Drawer, Table, Upload, message } from "antd";
import axios from "axios";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import CurrencyFormat from "react-currency-format";
import constants from "../../Constants";
const { Dragger } = Upload;
const { Panel } = Collapse;
const baseURL = constants.baseURL;
const Authorization = (method) => ({
  method,
  headers: {
    "Content-Type": "application/json",
    authorization: cookie.load("hq-id-qt"),
  },
});

const ReviewTransactions = ({ order }) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => {
        return order?.orderid?.split("-")[0] === text ? (
          <div className="orderid-review-transactions">{text}</div>
        ) : (
          text
        ); // highlight order id
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (text) => {
        try {
          return parseFloat(text)?.toFixed(2) + "%";
        } catch (error) {
          return text;
        }
      },
    },
    {
      title: "Matched",
      dataIndex: "ogdescription",
      key: "ogdescription",
    },
  ];
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({
    descriptionMatchedtransactions: [],
    amountMatchedtransactions: [],
    driverPayMatchedtransactions: [],
    vatCommissionMatchedtransactions: [],
  });

  const [transactions, setTransactions] = useState(
    JSON.parse(localStorage.getItem("transactions")) || []
  );
  // const showDrawer = () => {
  //   setVisible(true);
  // };

  const onClose = () => {
    setVisible(false);
  };
  useEffect(() => {
    // if open
    if (transactions.length > 0 && visible) {
      submitTransactions(transactions);
    }
    // eslint-disable-next-line
  }, [transactions]);

  const handleFileUpload = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const filteredData = results.data.map((row) => ({
          date: row.date,
          amount: parseFloat(row.amount),
          description: row.description,
        }));
        setTransactions(filteredData);
        submitTransactions(filteredData);
        // store filtered data in localstorage
        localStorage.setItem("transactions", JSON.stringify(filteredData));
      },
    });
    return false; // Prevent upload
  };

  const submitTransactions = (transactions, requestdownload) => {
    if (transactions.length === 0) {
      message.error(
        "No transactions to process, please upload a file from your bank",
        25,
        () => {
          //   onClose();
        }
      );
      return;
    }
    setLoading(true);
    axios
      .post(
        baseURL + "/check-transactions",
        {
          orderid: order.orderid,
          transactions: transactions,
          requestdownload,
        },
        Authorization("POST")
      )
      .then((response) => {
        message.success("Transactions processed successfully!");
        setResults(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        message.error("Failed to process transactions");
        setLoading(false);
      });
  };
  const uploadProps = {
    name: "file",
    multiple: false,
    accept: ".csv",
    beforeUpload: handleFileUpload,
    onRemove: () => setTransactions([]),
  };

  return (
    <>
      <button className={"paymentreceiver " + order?.paymentreceiver}>
        {order.paymentreceiver || "Review Transactions"}
      </button>
      <Drawer
        title={
          <>
            <b>Review Transactions</b> <br />
            <b>Order ID:</b>{" "}
            <div className="orderid-review-transactions">
              {order?.orderid?.split("-")[0]}
            </div>
            <i
              className="mdi dowload mdi-download"
              onClick={() => {
                submitTransactions([null], true);
              }}
            >
              {" "}
            </i>
          </>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        width={"70%"}
        afterOpenChange={() => {
          if (!visible) {
            // setTransactions([]);
            setResults({
              descriptionMatchedtransactions: [],
              amountMatchedtransactions: [],
              driverPayMatchedtransactions: [],
              vatCommissionMatchedtransactions: [],
            });
          } else {
            submitTransactions(transactions);
          }
        }}
      >
        {loading && <p>Processing transactions...</p>}
        <Dragger {...uploadProps} height={50}>
          <p className="ant-upload-hint">
            <InboxOutlined /> Strictly accept only CSV files.
          </p>
        </Dragger>
        <div>
          <p>
            <b>Amount:</b>{" "}
            <CurrencyFormat
              value={(order.sellingprice / 100).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            <br />
            <b>Commission:</b>
            <CurrencyFormat
              value={(order.commission / 100).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            <br />
            <b>Driver Pay:</b>
            <CurrencyFormat
              value={(order.driverpay / 100).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            <br />
            <b>VAT:</b>
            <CurrencyFormat
              value={(order.vat / 100).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
            ,
            <br />
            <b>Commission + VAT:</b>
            <CurrencyFormat
              value={((order.vat + order.commission) / 100).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
            <br />
            <b>Payment Receiver:</b> {order.paymentreceiver},
            <br />
            <b>Collection Date:</b> {order.collectiondate},
          </p>
        </div>
        <Collapse accordion defaultActiveKey={["1"]}>
          <Panel
            header={
              "Description Matches (" +
              results.descriptionMatchedtransactions.length +
              ")"
            }
            key="1"
            open={true}
          >
            <Table
              columns={columns}
              dataSource={results.descriptionMatchedtransactions}
              rowKey="description"
              pagination={{ pageSize: 10 }}
            />
          </Panel>
          <Panel
            header={
              "Amount Matches (" +
              results.amountMatchedtransactions.length +
              ")"
            }
            key="2"
          >
            <Table
              columns={columns}
              dataSource={results.amountMatchedtransactions}
              rowKey="description"
              pagination={{ pageSize: 10 }}
              //   render custome ui
            />
          </Panel>
          <Panel
            header={
              "VAT/Commission Matches (" +
              results.vatCommissionMatchedtransactions.length +
              ")"
            }
            key="3"
          >
            <Table
              columns={columns}
              dataSource={results.vatCommissionMatchedtransactions}
              rowKey="description"
              pagination={{ pageSize: 5 }}
            />
          </Panel>
          {/* Driver pay */}
          <Panel
            header={
              "Driver Pay Matches (" +
              results.driverPayMatchedtransactions.length +
              ")"
            }
            key="4"
          >
            <Table
              columns={columns}
              dataSource={results.driverPayMatchedtransactions}
              rowKey="description"
              pagination={{ pageSize: 5 }}
            />
          </Panel>
        </Collapse>
      </Drawer>
    </>
  );
};

export default ReviewTransactions;
