import about from "./pages/about";
import vehicles from "./pages/dashboard";
import hirepricesurvey from "./pages/hire-price-survey";
import landing from "./pages/landing";
import privacy from "./pages/privacy";
import terms from "./pages/terms";
import payments from "./pages/payments";
const routes = [
  { path: "/dashboard", component: payments },
  { path: "/vehicles", component: vehicles },
  { path: "/hire-price-survey", component: hirepricesurvey },
  { path: "/terms", component: terms },
  { path: "/privacy", component: privacy },
  { path: "/about", component: about },
  { path: "/howitworks", component: about },
  { path: "/contact", component: about },
  { path: "/payments", component: payments },
  { path: "/", component: landing },
];

export default routes;
