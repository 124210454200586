// src/PDFViewer.js
import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Document, Page, pdfjs } from "react-pdf";

// Set the workerSrc for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `pdf.worker.min.mjs`;

const PDFViewer = ({ pdfUrl }) => {
  const [visible, setVisible] = useState(false);
  const [numPages, setNumPages] = useState(null);

  // Replace this URL with your PDF's URL

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Preview PDF
      </Button>
      <Modal
        title="PDF Preview"
        open={visible}
        onCancel={handleCancel}
        footer={null}
        width="80%"
        style={{ top: 20 }}
        styles={{
          content: {
            overflow: "auto",
            maxHeight: "100%",
            height: "80vh",
            overflowY: "auto",
          },
        }} // Ensures the modal content is scrollable
      >
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<div>Loading PDF...</div>}
          error={(error) => {
            console.error(error);
            return <div>Failed to load PDF.</div>;
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={600}
              loading={<div>Loading page {index + 1}...</div>}
            />
          ))}
        </Document>
      </Modal>
    </>
  );
};

export default PDFViewer;
